import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6"

function Body() {
    return (
        <div className="maincontainer mb-10 h-[70vh] flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <p className="text-5xl">404 Page Not Found</p>
                <Link to={"/"} className="mt-10 flex items-center">
                    <FaArrowLeftLong className="mr-2" />Back to main Page
                </Link>
            </div>
        </div>
    );
}
export default Body;
