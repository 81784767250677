import React from "react";
import { HiExternalLink } from "react-icons/hi"
import Roadmap from "../components/roadmap";
import { BiLogoReact, BiLogoNodejs, BiLogoTailwindCss, BiLogoMongodb } from "react-icons/bi"
import { TfiHtml5 } from "react-icons/tfi"
import { FaCss3, FaBootstrap } from "react-icons/fa"
import { DiJavascript1 } from "react-icons/di"
import { SiAdobephotoshop, SiAdobeillustrator } from "react-icons/si"
import { BsDatabase } from "react-icons/bs"
import { Link } from "react-router-dom";
import pdfpt from './cv/CV_Ruben_Castro_PT.pdf'
import pdfen from './cv/CV_Ruben_Castro_EN.pdf'
function About() {
  return (
    <div>
      <div className="mb-10 grid grid-cols-1 sm:grid-cols-2">
        <div className="mx-2 sm:mx-10 col-span-1 flex flex-col justify-center items-center">
          <div className="mx-4 sm:mx-0">
            <p className="text-3xl font-semibold text-start">Sou um Full-Stack Developer e Graphic Designer.</p>
            <hr className="border-white my-8" />
            <p className="text-sm text-start mb-8">A minha experiência como programador é marcada pela procura contínua da excelência, atenção meticulosa aos detalhes e cumprimento rigoroso de prazos. Com uma abordagem dinâmica, flexibilidade e proatividade, sou capaz de contribuir de forma significativa para qualquer organização em que atuo.
            </p>
            <a href={pdfen} target="blank_" className="flex items-center w-[100px]"><span className="whitespace-nowrap flex">Curriculum English <HiExternalLink className="ml-1 text-lg" /></span></a>
            <a href={pdfpt} target="blank_" className="flex items-center w-[100px]"><span className="whitespace-nowrap flex">Curriculum Português <HiExternalLink className="ml-1 text-lg" /></span></a>
            <hr className="border-white my-8 sm:border-transparent sm:my-0" />
          </div>
        </div>
        <div className="col-span-1">
          <img src="./images/pfp.jpg" alt="profile"></img>
        </div>
      </div >
      <div className="container mx-auto mt-14 grid grid-cols-2 sm:grid-cols-6 gap-y-6">
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 75 }}><TfiHtml5 className="text-4xl" /></div>
            <p className="mt-2 text-center">HTML5</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 85 }}><FaCss3 className="text-4xl" /></div>
            <p className="mt-2 text-center">CSS</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 70 }}><BiLogoReact className="text-5xl" /></div>
            <p className="mt-2 text-center">React JS</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 30 }}><BiLogoReact className="text-5xl" /></div>
            <p className="mt-2 text-center">React Native</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress justify-self-center" style={{ "--value": 75 }}><DiJavascript1 className="text-5xl" /></div>
            <p className="mt-2 text-center">Javascript</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 70 }}><BiLogoNodejs className="text-5xl" /></div>
            <p className="mt-2 text-center">Node.JS</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 60 }}><BsDatabase className="text-4xl" /></div>
            <p className="mt-2 text-center">SQL</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 55 }}><BiLogoMongodb className="text-5xl" /></div>
            <p className="mt-2 text-center">Mongodb</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 80 }}><BiLogoTailwindCss className="text-5xl" /></div>
            <p className="mt-2 text-center">Tailwind CSS</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 75 }}><FaBootstrap className="text-5xl" /></div>
            <p className="mt-2 text-center">Bootstrap</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 90 }}><SiAdobephotoshop className="text-4xl" /></div>
            <p className="mt-2 text-center">Photoshop</p>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex flex-col col-span-1 justify-center items-center">
            <div className="radial-progress" style={{ "--value": 85 }}><SiAdobeillustrator className="text-4xl" /></div>
            <p className="mt-2 text-center">Illustrator</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="my-12 w-[90%] px-6 justify-items-center mx-auto">
          <Roadmap
            steps={[
              { year: "08/23 05/23", title: "Full-stack Developer", subtitle: "Dionamite - Estágio", description: "Remote, Portugal", isDark: true },
              { year: "2023 2022", title: "Tecnologias e Programação de Sistemas de Informação CET", subtitle: "Citeforma", description: "Lisboa, Portugal", isDark: true },
              { year: "07/21 04/21", title: "Graphic Designer", subtitle: "Foco Criativo - Estágio", description: "Póvoa de Lanhoso, Braga", isDark: true },
              { year: "2021 2019", title: "Ilustração e Arte Gráficas CTeSP", subtitle: "IPCA", description: "Guimarães, Portugal", isDark: true },
              { year: "2019 2016", title: "Curso Profissional Tecnico Multimédia", subtitle: "ESCT", description: "Caldas das Taipas, Guimarães, Portugal", isDark: true },
            ]}
          />
        </div>
      </div>
    </div >
  );
}
export default About;
