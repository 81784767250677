import React from "react";
import { Link } from "react-router-dom";
import { HiExternalLink } from "react-icons/hi"

function Body() {
  return (
    <div className="maincontainer mb-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6 justify-items-center">
        <div className="relative col-span-1 group ">
          <img
            src={"./images/webbit/webbit-square.png"}
            className="images w-full h-full"
            alt="Project 1"
          />
          <div className="main-project-container">
            <Link
              to={"https://webbit.rubencastro.pt"}
              target="_blank"
              className="disable-link sm:enable-link"
            >
              <div className="flex flex-col justify-center h-full items-center text-justify mx-14">
                <p className="pb-4 font-bold text-xl">Webbit - Projeto Final</p>
                <p className="flex text-sm font-semibold text-white pb-4">● <span className="pl-2 pr-2">PHP</span> ● <span className="pl-2 pr-2">Bootstrap</span></p>
                <p>
                  Webbit é uma plataforma fortemente influenciada pelo Reddit. No entanto, em de comunidades ou "subreddits", o Webbit organiza o conteúdo em categorias. Construído em PHP + MVC com a framework Bootstrap.
                </p>
                <span className="visible sm:hidden pt-4">
                  <button
                    href="https://webbit.rubencastro.pt"
                    target="_blank"
                    className="flex justify-center items-center enable-link"
                  >Live Demo<HiExternalLink className="text-xl" /></button>
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="relative col-span-1 group">
          <img
            src={"./images/dionamite-web/dionamiteweb-square.jpg"}
            className="images w-full h-full"
            alt="Project 2"
          />
          <div className="main-project-container">
            <Link
              to={"https://dionamite.rubencastro.pt"}
              target="_blank"
              className="disable-link sm:enable-link"
            >
              <div className="flex flex-col justify-center h-full items-center text-justify mx-14">
                <p className="pb-4 font-bold text-xl">Dionamite - Wesbite</p>
                <p className="flex text-sm font-semibold text-white pb-4">● <span className="pl-2 pr-2">React JS</span> ● <span className="pl-2 pr-2">Tailwind</span></p>
                <p>
                  O website da Dionamite foi desenvolvido em contexto de estagio curricular, construído em React JS com a framework Tailwind.
                </p>
                <span className="visible sm:hidden pt-4">
                  <p
                    className="flex justify-center items-center enable-link "
                  >Live Demo<HiExternalLink className="text-xl" /></p>
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="relative col-span-1 group">
          <img
            src={"./images/dionamite-academy/dionamite-academy.jpg"}
            className="images w-full h-full"
            alt="Project 2"
          />

          <div className="main-project-container "><Link
            to={"https://dionamite.academy/"}
            target="_blank"
            className="disable-link sm:enable-link"
          >
            <div className="flex flex-col justify-center h-full items-center text-justify mx-14">
              <p className="pb-4 font-bold text-xl">Dionamite - Academy</p>
              <p className="flex text-sm font-semibold text-white pb-4">● <span className="pl-2 pr-2">React JS</span> ● <span className="pl-2 pr-2">Tailwind</span> ● <span className="pl-2 pr-2">Node.js</span> ● <span className="pl-2 pr-2">Mongodb</span></p>
              <p>
                O Projeto Dionamite - Academy foi desenvolvido em contexto de estagio curricular, construído em React JS, Node.js, Mongodb e a framework Tailwind.
              </p>
              <span className="visible sm:hidden pt-4">
                <p
                  className="flex justify-center items-center enable-link "
                >Live Demo<HiExternalLink className="text-xl" /></p>
              </span>
            </div>
          </Link>
          </div>
        </div>
        <div className="relative col-span-1 group">
          <img
            src={"./images/salpicosdacozinha/logo.jpg"}
            className="images w-full h-full"
            alt="Project 2"
          />

          <div className="main-project-container "><Link
            to={"https://salpicos.rubencastro.pt/"}
            target="_blank"
            className="disable-link sm:enable-link"
          >
            <div className="flex flex-col justify-center h-full items-center text-justify mx-14">
              <p className="pb-4 font-bold text-xl">Salpicos da Cozinha</p>
              <p className="flex text-sm font-semibold text-white pb-4">● <span className="pl-2 pr-2">React JS</span> ● <span className="pl-2 pr-2">Tailwind</span> ● <span className="pl-2 pr-2">Node.js</span> ● <span className="pl-2 pr-2">Mongodb</span></p>
              <p>
                Este projeto envolve uma plataforma destinada à desponibilização da ementa do Take Away Salpicos da Cozinha e os respetivos pedidos, construído com React JS, Node.js, Mongodb e a framework Tailwind.
              </p>
              <span className="visible sm:hidden pt-4">
                <p
                  className="flex justify-center items-center enable-link "
                >Live Demo<HiExternalLink className="text-xl" /></p>
              </span>
            </div>
          </Link>
          </div>
        </div>

      </div>
    </div>
  );
}
export default Body;
