

const RoadmapStep = ({ year, index, title, subtitle, description, isDark = false }) => {

    const baseClasses = "mb-8 flex justify-between items-center w-full";
    const baseOrderClasses = "order-1 rounded-lg shadow-xl px-6 py-6 z-10";
    const direction = (index % 2 === 0) ? 'md:flex-row-reverse md:left-timeline' : 'md:flex-row';
    const colorScheme = isDark ? 'bg-gray-800 text-white text-opacity-80' : 'bg-gray-800 text-white text-opacity-80';

    return (
        <div className={`${baseClasses} flex-col md:flex-row ${direction}`}>
            <div className="order-1 w-full md:w-5/12"></div>
            <div className="z-20 flex items-center order-1 w-[84px] bg-gray-100 shadow-xl p-1 rounded mx-6 mb-6 sm:mb-0">
                <p className="font-semibold text-base text-center text-gray-700">{year}</p>
            </div>
            <div className={`${baseOrderClasses} ${colorScheme} w-full md:w-5/12`}>
                <p className="font-bold text-xl">{title}</p>
                <p className="mb-1 text-lg font-medium">{subtitle}</p>
                <p className="text-sm leading-snug tracking-wide whitespace-pre-line">{description}</p>
            </div>
        </div>
    );
};


const Roadmap = ({ steps }) => {
    return (
        <div className="container mx-auto w-full h-full " >
            <div className="relative wrap overflow-hidden h-full">
                <div className="borderFade h-full left-1/2 md:h-full lg:h-full sm:h-full smm:h-full"  ></div>
                {steps.map((step, index) => (
                    <RoadmapStep key={index} index={index} year={step.year} subtitle={step.subtitle} title={step.title} description={step.description} isDark={step.isDark} />
                ))}
            </div>
        </div>
    );
};

export default Roadmap;
