import React from "react";

function Body() {
    return (
        <footer>
            <div className="mt-36 mb-10">
                <div className="text-center text-gray-500 text-sm">
                    2023 All rights reserved. Be kind.
                </div>
            </div>
        </footer>
    );
}
export default Body;
