import React, { useEffect, useState } from "react";
import logo from "../logo.svg";
import { VscGithub } from "react-icons/vsc";
import { Link, useLocation } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import { Squash as Hamburger } from 'hamburger-react'
function Navbar() {
  const location = useLocation();

  const [isMenuOpen, SetIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    SetIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";

    }
  }, [isMenuOpen]);

  return (
    <header>
      <div className="mt-6 mb-16 sm:container mx-6 sm:mx-auto">
        <nav className="flex justify-between items-center w-full">
          <Link to={"/Projects"} className="z-50">
            <img src={logo} className="h-11" alt="Rúben Castro" />
          </Link>
          {isMenuOpen ?
            <div className="absolute top-0 left-0 z-40 items-center h-[100vh] w-[100vw] bg-gray-900 animation">
              <div className="relative container flex justify-center items-center h-full">
                <div>
                  <Link to={"/Projects"} className="flex items-center justify-center pb-2">
                    <button type="button" className={`text-3xl hover:underline smoothanim underline-offset-8 ${location.pathname === '/Projects' ? 'underline underline-offset-8' : ''}`} aria-current={location.pathname === '/Projects' ? 'page' : undefined} onClick={toggleMenu}>Projetos</button>
                  </Link>
                  <Link to={"/About"} className="flex items-center justify-center pb-2">
                    <button type="button" className={`text-3xl hover:underline underline-offset-8 smoothanim ${location.pathname === '/About' ? 'underline underline-offset-8' : ''}`} aria-current={location.pathname === '/About' ? 'page' : undefined} onClick={toggleMenu}>Sobre Mim</button>
                  </Link>
                  <Link to={"/Contacts"} className="flex items-center justify-center">
                    <button type="button" className={`text-3xl hover:underline underline-offset-8 smoothanim ${location.pathname === '/Contacts' ? 'underline underline-offset-8' : ''}`} aria-current={location.pathname === '/Contacts' ? 'page' : undefined} onClick={toggleMenu}>Contactos</button>
                  </Link>
                  <div className="flex justify-center items-center">
                    <div className="absolute bottom-0 flex pb-10">
                      <Link
                        to={"https://github.com/rubenccastro"}
                        target="_blank"
                        className="icon-nav"
                      >
                        <VscGithub />
                      </Link>
                      <Link
                        to={"https://www.linkedin.com/in/rubenfcastro/"}
                        target="_blank"
                        className="icon-nav ml-4"
                      >
                        <BsLinkedin />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            :
            <div className="hidden sm:flex justify-between items-center w-full ">
              <div className="mr-auto">
                <Link to={"/Projects"} className="pl-6 button-nav">
                  <button type="button" className={`hover:underline smoothanim underline-offset-8 ${location.pathname === '/Projects' ? 'underline underline-offset-8' : ''}`} aria-current={location.pathname === '/Projects' ? 'page' : undefined}>Projetos</button>
                </Link>
                <Link to={"/About"} className="pl-6 button-nav">
                  <button type="button" className={`hover:underline underline-offset-8 smoothanim ${location.pathname === '/About' ? 'underline underline-offset-8' : ''}`} aria-current={location.pathname === '/About' ? 'page' : undefined}>Sobre Mim</button>
                </Link>
                <Link to={"/Contacts"} className="pl-6 button-nav">
                  <button type="button" className={`hover:underline underline-offset-8 smoothanim ${location.pathname === '/Contacts' ? 'underline underline-offset-8' : ''}`} aria-current={location.pathname === '/Contacts' ? 'page' : undefined}>Contactos</button>
                </Link>
              </div>
              <div className="flex">
                <Link
                  to={"https://github.com/rubenccastro"}
                  target="_blank"
                  className="icon-nav"
                >
                  <VscGithub />
                </Link>
                <Link
                  to={"https://www.linkedin.com/in/rubenfcastro/"}
                  target="_blank"
                  className="icon-nav ml-4"
                >
                  <BsLinkedin />
                </Link>
              </div>
            </div>
          }
          <div className={`sm:hidden smoothanim flex z-50`}>
            <Hamburger color="rgb(255 255 255" toggled={isMenuOpen} toggle={toggleMenu} />
          </div>
        </nav >
      </div >
    </header >
  );
}

export default Navbar;
