import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import { VscGithub } from "react-icons/vsc";
import { FaExclamation } from "react-icons/fa"
import { CgClose } from "react-icons/cg"
import { IoMdMail } from "react-icons/io"

function ToastNotification({ onClose }) {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            onClose();
        }, 10000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="toast notification">
            <div id="toast-default" className="flex items-center w-full max-w-md p-4 shadow text-white bg-red-800" role="alert">
                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8  rounded-lg bg-red-500 text-white"><FaExclamation /></div>
                <div className="ml-3 mr-3 text-sm font-normal">Formulário Temporariamente Indispónivel!</div>
                <button type="button" className="ml-auto -mx-1.5 -my-1.5 focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex items-center justify-center h-8 w-8 hover:bg-white/10 text-white hover:text-white" data-dismiss-target="#toast-default" aria-label="Close" onClick={onClose}><CgClose className="text-2xl" />
                </button>
            </div>
        </div>
    )
}



function Contacts() {
    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => {
        setShowToast(!showToast);
    };
    return (
        <div className="mx-auto container flex justify-center items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 h-screen pt-24 sm:pt-80">
                <div className="mx-2 col-span-1 flex flex-col items-center sm:items-end">
                    <div className="mx-4 sm:mx-0">
                        <p className="text-3xl font-semibold text-start ">Contactos</p>
                        <hr className="border-white my-8 w-[450px] sm:w-[500px]" />
                        <div className="flex justify-center items-center">
                            <Link
                                to={"https://github.com/rubenccastro"}
                                target="_blank"
                                className="icon-nav"
                            >
                                <VscGithub />
                            </Link>
                            <Link
                                to={"https://www.linkedin.com/in/rubenfcastro/"}
                                target="_blank"
                                className="icon-nav ml-4"
                            >
                                <BsLinkedin />
                            </Link>
                            <Link to={"mailto:me@rubencastro.pt"}
                                target="_blank"
                                className="icon-nav ml-4 text-4xl">
                                <IoMdMail />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="mx-2 col-span-1 flex flex-col items-center">
                    <div className="px-4 container max-w-screen-md">
                        <div className="space-y-8">
                            <div className="grid grid-cols-2 gap-x-4">
                                <div className="col-span-1">
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-300">Nome</label>
                                    <input type="text" id="name" className="shadow-sm border text-sm  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white shadow-sm-light  focus:outline-none" />
                                </div>
                                <div className="col-span-1">
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-300">Apelido</label>
                                    <input type="text" id="name" className="shadow-sm border text-sm  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white shadow-sm-light  focus:outline-none" />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-300">Email</label>
                                <input type="email" id="email" className="shadow-sm border text-sm  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white shadow-sm-light  focus:outline-none" />
                            </div>
                            <div>
                                <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-300">Titulo</label>
                                <input type="text" id="subject" className="block p-3 w-full text-sm   border  shadow-sm bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light  focus:outline-none" />
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-300">Mnesagem</label>
                                <textarea id="message" rows="6" className="block p-3 w-full text-sm   border  shadow-sm bg-gray-700 border-gray-600 placeholder-gray-400 text-white shadow-sm-light  focus:outline-none" ></textarea>
                            </div>
                            <button className="py-3 px-5 text-sm font-medium text-center text-white  bg-gray-700 sm:w-fit hover:bg-gray-800 smoothanim focus:outline-none" onClick={() => {
                                toggleToast();
                            }}>Enviar</button>
                        </div>
                        {showToast && <ToastNotification onClose={toggleToast} />}
                    </div>
                </div>
            </div >
        </div>
    );
}

export default Contacts