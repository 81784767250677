import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Body from "./components/body";
import About from "./components/About";
import Footer from "./components/Footer";
import Contacts from "./components/Contacts"
import _404 from "./components/404"
function App() {
  const PageRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/Projects" element={<Body />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contacts" element={<Contacts />} />
        <Route path="/*" element={<_404 />} />
      </Routes>
    );
  };

  return (
    <div>
      <Navbar />
      <PageRoutes />
      <Footer />
    </div>
  );
}

export default App;
